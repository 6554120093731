<template>
    <div class="removeUnsubscribedDashboard">
        <div class="removeUnsubscribedDashboard__header">
            <div class="removeUnsubscribedDashboard__title">Remove unsubscribed</div>
        </div>
        <div class="removeUnsubscribedDashboard__row">Source list</div>
        <input class="removeUnsubscribedDashboard__row" type="file" @change="uploadMainList" ref="mainFile">
        <div class="removeUnsubscribedDashboard__row">Unsubscribed list</div>
        <input class="removeUnsubscribedDashboard__row" type="file" @change="uploadUnsubscribed" ref="unsubscribedFile">
        <div class="removeUnsubscribedDashboard__button">
          <span class="text" @click="removeUnsubscribed">Remove unsubscribed</span>
        </div>
    </div>
</template>

<script>
import './RemoveUnsubscribed.styl'

export default {
    name: 'remove-unsubscribed',
    data: () => ({
      mainList: [],
      unsubscribedList: [],
    }),
    methods: {
      uploadMainList () {
        const reader = new FileReader();
        const scope = this;
        reader.onload = function (progressEvent) {
          scope.mainList = this.result.split(/\r?\n/);
        }
        reader.readAsText(this.$refs.mainFile.files[0]);
      },
      uploadUnsubscribed () {
        const reader = new FileReader();
        const scope = this;
        reader.onload = function (progressEvent) {
          scope.unsubscribedList = this.result.split(/\r?\n/);
        };
        reader.readAsText(this.$refs.unsubscribedFile.files[0]);
      },
      removeUnsubscribed () {
        for (let i = 0; i < this.mainList.length; i++) {
          const email = this.mainList[i].split(',')[0];
          if (this.unsubscribedList.includes(email)) {
            this.mainList.splice(i, 1);
            i--;
          }
        }
        const mainListText = this.mainList.join('\n');
        try {
          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', mainListText]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'list_without_unsubscribed.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e)
        }
      },
    },
}
</script>